<template>
  <input
    :type="inputProps?.typeInput ?? 'text'"
    :placeholder="inputProps?.placeholder"
    :id="inputProps?.id"
    :class="$vuetify.breakpoint.smAndDown ? 'smd' : ''"
    :value="value"
    @input="handleChangeInput($event.target.value, $event)"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
    @keyup.enter="$emit('enter')"
    autocomplete="off"
    ref="input"
  />
</template>

<script>
import { validateInputTypeNumber } from "@/uttil";

export default {
  props: {
    value: {},
    inputProps: {
      typeInput: {
        type: String,
        default: "text",
      },
      placeholder: {
        type: String,
        default: "",
      },
      id: {
        type: String,
        default: "",
      },
      appendIcon: {
        type: String,
        default: "",
      },
      prependIcon: {
        type: String,
        default: "",
      },
      autoFocus: {
        type: Boolean,
        default: false,
      },
    },
  },
  methods: {
    getIcon(name) {
      return require("@/assets/icons/" + name + ".svg");
    },
    handleChangeInput(value, event) {
      if (
        this.inputProps?.typeInput !== "number" ||
        validateInputTypeNumber(event)
      )
        this.$emit("input", value);
      else return;
    },
  },
  mounted() {
    !this.$vuetify.breakpoint.mobile && this.$refs.input.focus();
  },
};
</script>

<style lang="sass" scoped>
input
  width: 100%
  padding: 16px 0 16px 16px
  border: 1px solid #D9DFEA
  border-radius: 12px
  background-color: #F9FBFC
  min-width: 305px
  z-index: 2
  position: relative
  &.smd
    min-width: unset
</style>
