import axios from "@/services/axios";

const BASE_URL = "admin/";

export default {
  //  APIs Folder
  async fetchFolderList(context) {
    await axios
      .get(BASE_URL + "folders")
      .then((res) => {
        if (res.data.status_code == "200") {
          context.commit("SET_FOLDER_LIST", res.data.data);
        } else {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async createNewFolder(context, folderName) {
    await axios
      .post(BASE_URL + "folder", {
        folderName: folderName,
      })
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async deleteFolder(context, folderId) {
    await axios
      .delete(`${BASE_URL}folder/${folderId}`)
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async updateFolder(context, { folderId, folderName }) {
    await axios
      .put(BASE_URL + `folder/${folderId}`, {
        folderName: folderName,
      })
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  selectFolder(context, { folderId }) {
    context.commit("SET_CURRENT_FOLDER_ID", folderId);
  },

  // End of APIs Folder

  //  APIs Card Group
  async fetchCardSets(context, folderId) {
    await axios
      .get(`${BASE_URL}groupCards`, {
        params: {
          ...(folderId && { folderId: folderId }),
        },
      })
      .then((res) => {
        if (res.data.status_code == "200") {
          context.commit("SET_CARD_SETS", res.data.data);
        } else {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async createNewCardSet(context, payload) {
    await axios
      .post(BASE_URL + "groupCard", payload)
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async duplicateCardSet(context, payload) {
    await axios
      .post(BASE_URL + "groupCard/duplicate", payload)
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async deleteCardSet(context, cartSetId) {
    await axios
      .delete(`${BASE_URL}groupCard/${cartSetId}`)
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async updateCardSet(context, { cardSetId, cardSetName, folderId }) {
    await axios
      .put(`${BASE_URL}groupCard/${cardSetId}`, {
        folderId: folderId,
        groupName: cardSetName,
      })
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async assignCardGroup(context, payload) {
    await axios
      .post(`${BASE_URL}groupCard/${payload.groupCardId}`, payload.body)
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async assignProCardGroup(context, payload) {
    await axios
      .post(`student/groupCard/${payload.groupCardId}`, payload.body)
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async moveProCardGroupOfAStudent(
    context,
    { groupCardId, folderId, toUserId }
  ) {
    await axios
      .put(`${BASE_URL}groupCard/move/${groupCardId}`, { folderId, toUserId })
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async moveProCardGroupToOthers(
    context,
    { groupCardId, folderId, fromUserId, toUserId }
  ) {
    await axios
      .put(`${BASE_URL}groupCard/move/${groupCardId}`, {
        folderId,
        fromUserId,
        toUserId,
      })
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async editMultipleProCard(
    context,
    { folderId, groupCardId, typeCard, detailCardIds, userId }
  ) {
    await axios
      .put(`${BASE_URL}cards`, {
        folderId,
        groupCardId,
        typeCard,
        detailCardIds,
        userId,
      })
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  // End of  APIs Card Group

  // APIs Class
  async fetchClasses(context) {
    await axios
      .get(`${BASE_URL}classes`)
      .then((res) => {
        if (res.data.status_code == "200") {
          context.commit("SET_CLASSES", res.data.data);
        } else {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async createNewClass(context, className) {
    await axios
      .post(BASE_URL + "class", { className: className })
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async deleteClass(context, classId) {
    await axios
      .delete(`${BASE_URL}class/${classId}`)
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async updateClass(context, { classId, newClassName }) {
    await axios
      .put(`${BASE_URL}class/${classId}`, {
        className: newClassName,
      })
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async fetchUnassignedClasses(context, cardGroupID) {
    await axios
      .get(`${BASE_URL}accounts/${cardGroupID}`)
      .then((res) => {
        if (res.data.status_code == "200") {
          context.commit("SET_UNASSIGNED_CLASSES", res.data.data);
        } else {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async fetchProClasses(context) {
    await axios
      .get(`${BASE_URL}classes/pro`)
      .then((res) => {
        if (res.data.status_code == "200") {
          context.commit("SET_CLASSES_PRO", res.data.data);
        } else {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  // End of APIs Card Group

  // APIs Account
  async fetchAccounts(context) {
    await axios
      .get(BASE_URL + "accounts")
      .then((res) => {
        if (res.data.status_code == "200") {
          context.commit("SET_ACCOUNT_LIST", res.data.data);
        } else {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        context.commit("SET_ERROR_ACCOUNT", {
          error: true,
          message: error.response.data.message,
          errorCode: error.response.data.status_code,
        });
      });
  },

  async createNewAccount(context, data) {
    await axios
      .post(BASE_URL + "account", data)
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        context.commit("SET_ERROR_ACCOUNT", {
          error: true,
          message: error.response.data.message,
          errorCode: error.response.data.status_code,
        });
      });
  },

  async deleteAccount(context, accountID) {
    await axios
      .delete(`${BASE_URL}account/${accountID}`)
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        context.commit("SET_ERROR_ACCOUNT", {
          error: true,
          message: error.response.data.message,
          errorCode: error.response.data.status_code,
        });
      });
  },

  async updateAccount(context, data) {
    await axios
      .put(BASE_URL + `account/${data.id}`, {
        data,
      })
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        context.commit("SET_ERROR_ACCOUNT", {
          error: true,
          message: error.response.data.message,
          errorCode: error.response.data.status_code,
        });
      });
  },

  async getLearningHistory(context, payload) {
    try {
      const res = await axios.get(
        `user/screenshots/${payload.studentId}/${payload.date}`
      );

      if (res.data.status_code !== "200") {
        throw new Error(res.data.message || "Something is wrong!");
      }
      return res.data.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  // End of APIs Account

  async fetchLearningResults(context, payload) {
    await axios
      .post(`${BASE_URL}learning-mng-table`, {
        ...payload,
      })
      .then((res) => {
        if (res.data.status_code == "200") {
          const data = res?.data?.data || [];
          if (data) {
            const convertData = data.map((record) => {
              const { listInfo, ...newRecord } = record;
              listInfo.forEach((info) => {
                let obj = {};
                let timeAvg = 0;
                if (info.totalCardsInDay && info.totalLearnTimeInDay) {
                  timeAvg = (
                    info.totalLearnTimeInDay / info.totalCardsInDay
                  ).toFixed(1);
                }
                obj.totalLearnTimeInDay = `${timeAvg} s/thẻ`;
                obj.totalCardsInDay = `${info.totalCardsInDay ?? 0} thẻ`;
                obj.isComplete = true;
                if (info.history.length > 0) {
                  const history = info.history[0];
                  // const numOfNewCardsLearned =
                  //   history.numOfNewCardsLearned ?? 0;
                  // const numOfReviewCardsLearned =
                  //   history.numOfReviewCardsLearned ?? 0;
                  // const numOfNewCardsNeedLearn =
                  //   history.numOfNewCardsNeedLearn ?? 0;
                  // const numOfReviewCardsNeedLearn =
                  //   history.numOfReviewCardsNeedLearn ?? 0;
                  // if (
                  //   numOfNewCardsLearned < numOfNewCardsNeedLearn ||
                  //   numOfReviewCardsLearned < numOfReviewCardsNeedLearn
                  // ) {
                  //   obj.isComplete = false;
                  // }
                  // if (
                  //   info.date ==
                  //   moment().subtract(4, "hours").format("YYYY-MM-DD")
                  // ) {
                  //   obj.isComplete = history.statusTick;
                  // }
                  obj.isComplete = history.statusTick;
                }
                newRecord[info.date] = { ...obj };
              });
              return {
                ...newRecord,
              };
            });
            context.commit("SET_LEARNING_RESULTS", convertData);
          }
        } else {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async resetAssignCardPerDay(context, payload) {
    await axios
      .post(BASE_URL + "reset/resetAssign", payload)
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        throw error;
      });
  },
};
