import Vue from "vue";
import VueRouter from "vue-router";
import AdminLoginView from "@/views/auth/AdminLoginView.vue";
import ListView from "@/views/list/ListView.vue";
import BrowseView from "@/views/browse/BrowseView.vue";
import CardQuestionView from "@/views/card/CardQuestionView.vue";
import CardAnswerView from "@/views/card/CardAnswerView.vue";
import TabsView from "@/views/admin/TabsView.vue";
import TokenService from "@/services/token.service";
import { ACCOUNT_ROLE } from "@/constants";

Vue.use(VueRouter);
const routes = [
  //   ---------------student----------------
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: AdminLoginView,
    meta: { isLoginPage: true, role: ACCOUNT_ROLE.STUDENT },
  },
  {
    path: "/group-card/:studentId",
    name: "list",
    component: ListView,
  },
  {
    path: "/learn-card/:studentId/:learningGroupCardId",
    name: "cardDetailsQuestion",
    props: true,
    component: CardQuestionView,
  },
  {
    path: "/answer/:studentId/:learningGroupCardId",
    name: "cardDetailsAnswer",
    props: true,
    component: CardAnswerView,
  },
  {
    path: "/browse/:studentId",
    name: "browse",
    component: BrowseView,
  },
  //   ---------------admin----------------
  {
    path: "/admin",
    name: "loginAdmin",
    component: AdminLoginView,
    meta: { isLoginPage: true, role: ACCOUNT_ROLE.ADMIN },
  },
  {
    path: "/manage",
    name: "manage",
    component: TabsView,
    meta: { role: ACCOUNT_ROLE.ADMIN },
  },
  {
    path: "/manage-student-group-card/:studentId",
    name: "manageStudentGroupCard",
    component: ListView,
    meta: { role: ACCOUNT_ROLE.ADMIN },
  },
  {
    path: "/browse-admin/student/:studentId",
    name: "browseAdmin",
    component: BrowseView,
    meta: { role: ACCOUNT_ROLE.ADMIN },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// ---------------- guard router using token in localStorage ---------------- /
router.beforeEach((to, from, next) => {
  if (!to.meta.isLoginPage && !localStorage.getItem("accessToken")) {
    if (to.meta.role) next("/admin");
    else next("/login");
  } else if (to.meta.isLoginPage && !!localStorage.getItem("accessToken")) {
    if (to.meta.role) next("/manage");
    else next(`/group-card/${TokenService.getAccount().id}`);
  } else {
    if (!localStorage.getItem("accessToken")) next();
    else if (TokenService.getAccount().role === "student" && to.meta.role)
      next(`/group-card/${TokenService.getAccount().id}`);
    else if (
      ["subadmin", "admin"].includes(TokenService.getAccount().role) &&
      !to.meta.role
    )
      next("/manage");
    else next();
  }
});

export default router;
