<template>
  <v-container fluid>
    <LayoutCard
      :title="getTitle"
      subtitle="Bộ thẻ:"
      type="CÂU HỎI"
      :timeLeft="estimateTime"
      @openDeleteDialog="handleOpenDeleteDialog"
      @back="handleBackPage"
      @pressEnter="handleAnswer"
      @backCard="handleBackCard"
      @saveCard="handleClickSaveCard"
      @unSaveCard="handleClickUnSaveCard"
      @rightSingleTap="handleAnswer"
      @leftDoubleTap="handleBackCard"
      @rightDoubleTap="handlePlayAudio"
      :disableActions="isProcessing"
      :showLoading="showLoading"
    >
      <div
        class="d-flex justify-content-center align-items-center"
        v-if="showReload"
        :style="{
          minHeight: $vuetify.breakpoint.xsOnly
            ? 'calc(100vh - 330px)'
            : '375px',
        }"
      >
        <ButtonComponent
          title="Tải lại"
          append-icon="reload"
          @click="handleReload"
          classBtn="submit-answer"
          smallText
        />
      </div>
      <div
        class="d-flex justify-content-center align-items-center"
        v-else-if="isLoadingNextCard || showLoading"
        :style="{
          minHeight: $vuetify.breakpoint.xsOnly
            ? 'calc(100vh - 330px)'
            : '375px',
        }"
      >
        <v-progress-circular color="primary" indeterminate size="64" />
      </div>
      <v-layout
        v-else
        class="d-flex flex-column disable-copy"
        :style="{
          minHeight: $vuetify.breakpoint.xsOnly
            ? 'calc(100vh - 330px)'
            : 'unset',
        }"
      >
        <div
          class="mb-2 justify-center d-flex"
          :class="{
            'mb-4': $vuetify.breakpoint.xsOnly,
          }"
        >
          <!--  ------------- Question title ----------------- -->

          <div class="col justify-center">
            <div
              v-if="
                currentCard.typeCard == 1 && this.currentCard.audioLink !== null
              "
              class="d-flex justify-content-center"
            >
              <img
                class="cursor-pointer"
                @click="handlePlayAudio"
                src="@/assets/icons/audio.svg"
              />
              <!-- <audio
                v-if="currentCard.audioLink"
                ref="audioPlayer"
                muted
                autoplay
                preload="auto"
              >
                <source :src="currentCard.audioLink" type="audio/mpeg" />
                <source :src="currentCard.audioLink" type="audio/ogg" />
                <source :src="currentCard.audioLink" type="audio/wav" />
                <source :src="currentCard.audioLink" type="audio/mp4" />
                Your browser does not support the audio element.
              </audio> -->
              <audio
                :src="currentCard.audioLink"
                ref="audioPlayer"
                type="audio/*"
                preload="auto"
                autoplay
                muted
              />
            </div>
            <div
              v-else-if="[4, 5].includes(currentCard.typeCard)"
              class="d-flex justify-content-center"
            >
              <p
                class="word"
                :class="{
                  fw400: currentCard.typeCard == 5,
                  fw600: currentCard.typeCard == 4,
                  mobile: $vuetify.breakpoint.xsOnly,
                }"
                v-html="
                  currentCard.typeCard == 4
                    ? currentCard.mean
                    : formatBreakLineInEditor(currentCard.meanFormatted)
                "
              ></p>
            </div>
            <div
              v-else-if="[2, 3].includes(currentCard.typeCard)"
              class="d-flex justify-content-center"
            >
              <p
                class="word fw400"
                :class="{
                  mobile: $vuetify.breakpoint.xsOnly,
                }"
                v-html="currentCard.wordFormatted"
              ></p>
            </div>
            <div
              v-if="
                [1, 4].includes(currentCard.typeCard) &&
                currentCard.wordType != 'null'
              "
            >
              <p
                class="text-center word-type"
                :class="{
                  mobile: $vuetify.breakpoint.xsOnly,
                }"
                style="color: #f56742"
              >
                ({{ currentCard.wordType }})
              </p>
            </div>
          </div>
        </div>
        <!--  --------------------Input answer------------------- -->
        <div>
          <div class="mb-2">
            <label for="answer">
              <h6>Câu trả lời:</h6>
            </label>
          </div>
          <InputComponent
            @blur="isFocus = false"
            @focus="isFocus = true"
            :inputProps="answerInput"
            v-model="answerValue"
            :autoFocus="
              currentCard.typeCard == 4 && $vuetify.breakpoint.smAndUp
            "
          />
        </div>

        <div class="d-flex align-center justify-space-between mt-4">
          <div
            class="d-flex"
            :class="{
              'flex-column': $vuetify.breakpoint.xsOnly,
            }"
          >
            <p>
              Số thẻ mới:<span class="ml-2 text-1">{{
                numOfNewCardsWhenStartingLearn - numOfNewCardsLearning >= 0
                  ? numOfNewCardsWhenStartingLearn - numOfNewCardsLearning
                  : 0
              }}</span>
            </p>
            <p :class="{ 'ml-8': $vuetify.breakpoint.smAndUp }">
              Số thẻ cần ôn:<span class="ml-2 text-2">{{
                numOfRVSCardsWhenStartingLearn - numOfRVSCardsLearning >= 0
                  ? numOfRVSCardsWhenStartingLearn - numOfRVSCardsLearning
                  : 0
              }}</span>
            </p>
          </div>
          <CountdownTimer
            v-if="
              !showLoading &&
              currentGroupCard?.countDown?.show &&
              currentGroupCard?.countDown?.time
            "
            :time="countdownTime"
          ></CountdownTimer>
          <ButtonComponent
            title="Đáp án"
            append-icon="arrow-right"
            @click="handleAnswer"
            classBtn="submit-answer"
            :disable="!currentCard?.id"
            smallText
          />
        </div>
      </v-layout>
      <DialogComponent
        :showDialogValue="showDialogDelete"
        typeDialog="delete"
        @closeDialog="showDialogDelete = false"
        @confirmRequest="handleConfirmDeleteCard"
      />
      <DialogComponent
        :showDialogValue="showSuccessDiaLog"
        typeDialog="success"
        @closeDialog="handleCloseDialogSuccess"
      />
      <!-- <DialogComponent
        :showDialogValue="doneHomeWork"
        typeDialog="complete"
        @closeDialog="handleCloseDialogComplete"
      /> -->
    </LayoutCard>
  </v-container>
</template>

<script>
import LayoutCard from "@/components/layout/LayoutCard.vue";
import InputComponent from "@/components/ui/InputComponent.vue";
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import DialogComponent from "@/components/ui/DialogComponent.vue";
import TokenService from "@/services/token.service";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import {
  convertSecondsToTime,
  checkDoublePress,
  formatBreakLineInEditor,
} from "@/uttil";
import { getLocalStore } from "@/uttil/localStorage";
import {
  GROUP_CARD_TYPE_STORE_NAME,
  group_card_type,
} from "@/constants/groupcard";
import CountdownTimer from "@/views/card/CountdownTimer.vue";

export default {
  components: {
    DialogComponent,
    ButtonComponent,
    InputComponent,
    LayoutCard,
    CountdownTimer,
  },
  data() {
    return {
      componentKey: 0,
      answerInput: {
        placeholder: "Nhập câu trả lời của bạn",
        id: "answer",
      },
      answerValue: "",
      showDialogDelete: false,
      timeLeft: "Còn 1 giờ 16 phút",
      showLoading: true,
      showReload: false,
      isProcessing: false,
      openMenu: false,
      isFocus: false,
      timeout: null,
      formatBreakLineInEditor,
    };
  },
  computed: {
    ...mapGetters("card", [
      "getPreviousElementId",
      "getNextElementId",
      "getNextCard",
      "getPreviousCard",
      "getPreviousNumberOfCard",
      "havePreviousCard",
    ]),
    ...mapState("admin", ["cardSets"]),
    ...mapState("card", [
      "currentCard",
      "typeLearn",
      "previousCardId",
      "nextCard",
      "showSuccessDiaLog",
      "previousCard",
      "disableSaveBtn",
      "disableUnSaveBtn",
      "numOfNewCardsLearning",
      "numOfNewCardsWhenStartingLearn",
      "isBackLearnCard",
      "isLoadingNextCard",
      "doneCurrentGroupCardHomeWork",
      "showCompleteDiaLog",
      "numOfRVSCardsLearning",
      "numOfRVSCardsWhenStartingLearn",
      "doneHomeWork",
      "isOpenPopUpHomeWork",
      "currentLearningGroupCardTitle",
    ]),
    ...mapGetters("list", ["getListCardGroup"]),
    ...mapState("list", ["timeStudyAvg"]),
    payloadLearnCard() {
      const payload = {
        isClone: this.isClone,
        userGroupCardId:
          this.learningProGroupCardIds ?? this.learningGroupCardId,
        previousCardDetailId: this.previousCardId,
        typeLearn: this.isClone ? "" : this.typeLearn,
      };
      return payload;
    },
    account() {
      return TokenService.getAccount();
    },
    isClone() {
      const groupCardType = getLocalStore(GROUP_CARD_TYPE_STORE_NAME);
      return (
        groupCardType &&
        groupCardType == group_card_type.GROUP_CARD_CLONED_FROM_SAVED
      );
    },
    currentGroupCard() {
      if (this.learningProGroupCardIds) {
        const folders = this.getListCardGroup?.proFolders || [];
        return folders?.find(
          (item) => item.folderId == this.learningGroupCardId
        );
      } else {
        const cardGroups = this.getListCardGroup?.cardGroups || [];
        const normalCardGroup = cardGroups?.find(
          (item) => item.id == this.learningGroupCardId
        );
        if (normalCardGroup) {
          return normalCardGroup;
        } else {
          return (
            this.getListCardGroup?.proFolders
              ?.map((folder) => folder.cardGroups)
              ?.flat()
              ?.find((item) => item.id == this.learningGroupCardId) || []
          );
        }
      }
    },
    getTitle() {
      if (this.currentLearningGroupCardTitle)
        return this.currentLearningGroupCardTitle;
      if (this.currentGroupCard) {
        return `${
          this.currentGroupCard.folderName ?? this.currentGroupCard.groupName
        }`;
      } else {
        return "Không có bộ thẻ";
      }
    },
    countdownTime() {
      return this.currentGroupCard?.countDown?.time ?? 0;
    },
    getNumberOfNewCard() {
      if (this.currentGroupCard) {
        return `${this.currentGroupCard.newCard}`;
      } else {
        return 0;
      }
    },
    getNumberOfRevisingCard() {
      if (this.currentGroupCard) {
        if (this.isClone) {
          return `${this.currentGroupCard.totalCards}`;
        }
        return `${this.currentGroupCard.revisingCard}`;
      } else {
        return 0;
      }
    },
    learningGroupCardId() {
      return this.$route.params.learningGroupCardId;
    },
    learningProGroupCardIds() {
      return this.$route.query.learningGroupCardIds;
    },
    studentId() {
      return this.$route.params.studentId;
    },
    estimateTime() {
      let numCardLeft =
        3 * parseInt(this.getNumberOfNewCard) +
        parseInt(this.getNumberOfRevisingCard);
      return "Còn " + convertSecondsToTime(numCardLeft * this.timeStudyAvg);
    },
  },
  async created() {
    if (this.isBackLearnCard) {
      this.setNextCardToCurrentCard(this.getPreviousCard);
      this.POP_PREVIOUS_CARD();
      this.POP_PREVIOUS_NUMBER_OF_CARD();
      this.SET_IS_BACK_LEARN_CARD(false);
      return;
    } else if (this.nextCard.id > 0) {
      this.showLoading = true;
      this.setNextCardToCurrentCard(this.nextCard);
      this.showLoading = false;
      if (this.currentGroupCard?.countDown?.time)
        this.timeout = setTimeout(this.handleAnswer, this.countdownTime * 1000);
      return;
    }

    if (!this.showSuccessDiaLog) {
      try {
        await this.getNextLearnCardFromGroup(this.payloadLearnCard);
      } catch (e) {
        this.showReload = true;
        console.log(e);
      }
    }

    if (!this.currentLearningGroupCardTitle) {
      this.showLoading = true;
      await this.fetchStudentCardGroupList({
        studentId: this.studentId,
        userGroupCardId: this.learningGroupCardId || "",
      });
      this.SET_CURRENT_LEARNING_GROUP_CARD_TITLE(this.getTitle);
      this.showLoading = false;
    }
    if (this.currentGroupCard?.countDown?.time)
      this.timeout = setTimeout(this.handleAnswer, this.countdownTime * 1000);
  },
  mounted() {
    this.handlePlayAudio();
    this.fetchTimeStudy({ studentId: this.studentId });
    this.SET_DISABLE_SAVE(false);
    this.SET_DISABLE_UN_SAVE(false);
    this.SET_DONE_HOME_WORK(
      this.getListCardGroup.isCompleteHomeWork && !this.isOpenPopUpHomeWork
    );
    window.addEventListener("keydown", this.handleKeyPressed);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("keydown", this.handleKeyPressed);
    });
    this.showLoading = false;
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: {
    ...mapActions("list", [
      "handleRemoveLearnCard",
      "fetchStudentCardGroupList",
      "fetchTimeStudy",
    ]),
    ...mapActions("admin", ["fetchCardSets"]),
    ...mapActions("card", [
      "getNextLearnCardFromGroup",
      "setClosePopupSuccess",
      "setNextCardToCurrentCard",
      "resetCard",
      "setTypeLearn",
      "resetLearnCard",
      "handleSaveCard",
      "handleUnSaveCard",
    ]),
    ...mapMutations("card", [
      "SET_ANSWER",
      "SET_PREVIOUS_CARD_ID",
      "SET_PREVIOUS_CARDS_EMPTY",
      "SET_PREVIOUS_NUMBER_OF_CARD_EMPTY",
      "SET_START_TIME",
      "SET_SHOW_SUCCESS",
      "SET_DISABLE_SAVE",
      "SET_DISABLE_UN_SAVE",
      "SET_NUM_NEW_CARDS_LEARNING",
      "SET_NUM_NEW_CARDS_WHEN_STARTING_LEARNING",
      "SET_IS_BACK_LEARN_CARD",
      "POP_PREVIOUS_CARD",
      "POP_PREVIOUS_NUMBER_OF_CARD",
      "SET_SHOW_COMPLETE",
      "SET_DONE_HOME_WORK",
      "SET_NUM_RVS_CARDS_LEARNING",
      "SET_NUM_RVS_CARDS_WHEN_STARTING_LEARNING",
      "SET_IS_OPEN_POP_UP_HOME_WORK",
      "SET_CURRENT_LEARNING_GROUP_CARD_TITLE",
    ]),
    // ---------------------- Handle open and close dialogs -------------------- //
    handleOpenDeleteDialog() {
      this.showDialogDelete = true;
    },
    handleCloseDialogSuccess() {
      this.setClosePopupSuccess(false);
      this.$router.push(`/group-card/${this.account.id}`);
    },
    handleBackPage() {
      this.resetCard();
      this.$router.push(`/group-card/${this.account.id}`);
      this.SET_PREVIOUS_CARD_ID(null);
      this.SET_PREVIOUS_CARDS_EMPTY();
      this.SET_PREVIOUS_NUMBER_OF_CARD_EMPTY();
    },
    async handleAnswer() {
      this.SET_ANSWER(this.answerValue);
      this.setTypeLearn(this.currentCard.type);
      this.SET_PREVIOUS_CARD_ID(this.currentCard.detailCardId);
      this.getNextLearnCardFromGroup(this.payloadLearnCard);
      this.$router
        .push(
          `/answer/${this.studentId}/${this.learningGroupCardId}${
            this.learningProGroupCardIds
              ? "?learningGroupCardIds=" + this.learningProGroupCardIds
              : ""
          }`
        )
        .catch((err) => {
          console.log(err);
        });
    },
    //   ----------Delete card + back card from list cards action-------- //
    async handleConfirmDeleteCard() {
      this.showDialogDelete = false;
      await this.handleRemoveLearnCard(this.currentCard.id);
      if (!this.currentLearningGroupCardTitle) {
        this.fetchStudentCardGroupList({
          studentId: this.studentId,
          userGroupCardId: this.learningGroupCardId || "",
        });
        this.SET_CURRENT_LEARNING_GROUP_CARD_TITLE(this.getTitle);
      }
      if (this.nextCard.id > 0) {
        this.setNextCardToCurrentCard(this.nextCard);
        return;
      }

      if (!this.showSuccessDiaLog) {
        try {
          await this.getNextLearnCardFromGroup(this.payloadLearnCard);
        } catch (e) {
          this.showReload = true;
          console.log(e);
        }
      }
    },
    async handleBackCard() {
      let previousCard = this.getPreviousCard;
      this.resetLearnCard(previousCard);
      this.setNextCardToCurrentCard(previousCard);
      const { newLearned, RVSCardStartLearning, numOfRVSCardsLearned } =
        this.getPreviousNumberOfCard;
      this.SET_NUM_NEW_CARDS_LEARNING(newLearned);
      this.SET_NUM_RVS_CARDS_WHEN_STARTING_LEARNING(RVSCardStartLearning);
      this.SET_NUM_RVS_CARDS_LEARNING(numOfRVSCardsLearned);
      this.POP_PREVIOUS_CARD();
      this.POP_PREVIOUS_NUMBER_OF_CARD();
      this.setTypeLearn(previousCard.type);
    },
    handlePlayAudio() {
      const audioPlayer = this.$refs.audioPlayer;
      if (audioPlayer) {
        audioPlayer.muted = false;
        audioPlayer.currentTime = 0;
        audioPlayer.play();
      }
    },
    handleReload() {
      window.location.reload();
      this.showReload = false;
    },
    async handleClickSaveCard() {
      if (this.disableSaveBtn) {
        return;
      }
      const payload = {
        studentId: this.account.id,
        learnCardIdList: [this.currentCard.id],
      };
      const mess = await this.handleSaveCard(payload);
      if (!mess) {
        this.reloadTotalCard();
      }
      this.openMenu = false;
    },
    async handleClickUnSaveCard() {
      if (this.disableUnSaveBtn) {
        return;
      }
      const payload = {
        learnCardIdList: [this.currentCard.id],
      };
      const mess = await this.handleUnSaveCard(payload);
      if (!mess) {
        this.reloadTotalCard();
      }
      this.openMenu = false;
    },
    async reloadTotalCard() {
      if (this.currentCard.Qn == 0 && this.currentCard.type == 1) {
        this.SET_NUM_NEW_CARDS_WHEN_STARTING_LEARNING(
          this.numOfNewCardsWhenStartingLearn - 1
        );
      } else {
        this.SET_NUM_RVS_CARDS_WHEN_STARTING_LEARNING(
          this.numOfRVSCardsWhenStartingLearn - 1
        );
      }
    },
    handleCloseDialogComplete() {
      this.SET_DONE_HOME_WORK(false);
      this.SET_IS_OPEN_POP_UP_HOME_WORK(true);
    },
    handleKeyPressed(e) {
      if (!this.isFocus) {
        e.preventDefault();
        switch (e.key) {
          case "Enter":
            this.handleAnswer();
            break;
          case "r":
            if (!this.isFocus) this.handlePlayAudio();
            break;
          case "z":
            if (!this.isFocus && this.havePreviousCard) this.handleBackCard();
            break;
          case "x":
            if (!this.isFocus) checkDoublePress(this.handleOpenDeleteDialog);
            break;
          case "s":
            if (!this.isFocus) checkDoublePress(this.handleClickSaveCard);
            break;
          default:
            break;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.disable-copy {
  user-select: none;
}
img {
  z-index: 2;
}
* {
  color: #384961;
}
.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  margin-bottom: 0px;
}
.word {
  font-size: 28px;
  font-weight: 700;
  color: #1bb763;
  white-space: pre-line;
  text-align: center;
  &.fw400 {
    font-weight: 400;
  }
  &.mobile {
    font-size: 22px;
    font-weight: 400;
    &.fw600 {
      font-weight: 600;
    }
  }
}
.word-type {
  font-weight: 600;
  font-size: 22px;
  &.mobile {
    font-size: 21px;
  }
}
.audio {
  gap: 5px;
  .spelling {
    font-weight: 600;
    font-size: 16px;
    color: #384961;
  }
}
</style>

<style lang="sass">
// will change later
.text-1
  color: #3887FE
.text-2
  color: #1BB763
.justify-content-center
  justify-content: center
.justify-between
  justify-content: space-between
.text-center
  text-align: center
.align-items-center
  align-items: center
</style>
