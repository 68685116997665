/* eslint-disable prettier/prettier */
import axios from "@/services/axios";

const BASE_URL = "admin/";

export default {
  async createNewCard(context, payload) {
    await axios
      .post(BASE_URL + "card", payload, {
        headers: {
          "Content-Type": "multipart/encrypted",
        },
      })
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async fetchAllCards(context, { id: cardSetId, isPro = false }) {
    await axios
      .get(BASE_URL + "cards", {
        params: {
          ...(cardSetId && { groupCardId: cardSetId }),
        },
      })
      .then((res) => {
        if (isPro) context.commit("SET_PRO_CARD_LIST", res.data.data);
        else context.commit("SET_CARD_LIST", res.data.data);
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async fetchAllCardsOfProFolder(context, { folderId, studentId }) {
    await axios
      .get(`${BASE_URL}cards/folder/${folderId}/student/${studentId}`)
      .then((res) => {
        context.commit("SET_PRO_CARD_LIST", res.data.data);
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async filterCards(
    context,
    { isSearchByWord, isSearchByMeaning, isSearchByExample, search }
  ) {
    await axios
      .post(BASE_URL + "cards/search", {
        isSearchByWord: isSearchByWord,
        isSearchByMeaning: isSearchByMeaning,
        isSearchByExample: isSearchByExample,
        search: search,
      })
      .then((res) => {
        context.commit("SET_CARD_LIST_FILTER", res.data.data);
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async filterProCards(
    context,
    {
      isSearchByWord,
      isSearchByMeaning,
      isSearchByExample,
      search,
      searchFromstudentId,
    }
  ) {
    await axios
      .post(
        BASE_URL + "cards/search-pro",
        searchFromstudentId
          ? {
            isSearchByWord: isSearchByWord,
            isSearchByMeaning: isSearchByMeaning,
            isSearchByExample: isSearchByExample,
            search: search,
            createdFor: searchFromstudentId,
          }
          : {
            isSearchByWord: isSearchByWord,
            isSearchByMeaning: isSearchByMeaning,
            isSearchByExample: isSearchByExample,
            search: search,
          }
      )
      .then((res) => {
        context.commit("SET_PRO_CARD_LIST_FILTER", res.data.data);
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async deleteCard(context, ids) {
    await axios
      .delete(`${BASE_URL}cards/`, {
        data: { ids },
      })
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async fetchCardDetail(context, cardId) {
    await axios
      .get(`${BASE_URL}card/${cardId}`)
      .then((res) => {
        context.commit("SET_CARD_DETAIL", res.data.data);
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async updateCardDetail(context, payload) {
    await axios
      .put(`${BASE_URL}card/${payload.id}`, payload.form, {
        headers: {
          "Content-Type": "multipart/encrypted",
        },
      })
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  handleGetElementIndex(context, payload) {
    const listCards = context.rootState.card.listCards;
    const currentIndex = listCards.findIndex((card) => card.id === payload);
    const nextElementId = listCards[currentIndex + 1].id;
    const previousElementId = listCards[currentIndex - 1].id;
    context.commit("handleGetElementIndex", {
      nextId: nextElementId,
      previousId: previousElementId,
    });
  },
  async getNextLearnCardFromGroup(context, payload) {
    context.commit("SET_IS_GET_LEARN_CARD", true);
    const { isClone, ...params } = payload;
    let url = "user/cards/nextLearnCard";
    if (isClone) url = "user/cards/nextLearnCardForCopy";
    if (payload.previousCardDetailId) {
      params.previousCardDetailId = payload.previousCardDetailId;
    }

    context.commit("SET_LOADING_NEXT_CARD", true);
    await axios
      .get(url, {
        params,
      })
      .then((res) => {
        context.commit("SET_LOADING_NEXT_CARD", false);
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        } else {
          if (!res.data.data) {
            context.commit("SET_NEXT_CARD", { id: 0 });
            if (context.state.doneCurrentGroupCard) {
              context.commit("SET_SHOW_SUCCESS", true);
            } else {
              context.commit("SET_DONE_CURRENT_GROUP_CARD", true);
              if (!params.previousCardDetailId) {
                context.commit("SET_SHOW_SUCCESS", true);
              }
            }
          } else {
            if (params.previousCardDetailId) {
              context.commit("SET_NEXT_CARD", res.data.data);
              context.commit("SET_PREVIOUS_TYPE_LEARN", payload.type);
              context.commit("SET_PREVIOUS_CARD_ID", payload.id);
            } else {
              context.commit("SET_CURRENT_CARD", res.data.data);
            }
          }
        }
      })
      .catch((error) => {
        context.commit("SET_LOADING_NEXT_CARD", false);
        console.log(error);
        throw error;
      });
    context.commit("SET_IS_GET_LEARN_CARD", false);
    context.commit("SET_CURRENT_GROUP_CARD_ID", payload.id);
  },
  setCurrentFolderId(context, payload) {
    context.commit("SET_CURRENT_GROUP_FOLDER_ID", payload);
  },
  async getListCards(context, payload) {
    context.commit("SET_LIST_CARDS", payload);
  },
  setTypeLearn(context, payload) {
    context.commit("SET_PREVIOUS_TYPE_LEARN", payload);
  },
  // gọi lúc bắt đầu học thẻ
  setStartTime(context) {
    context.commit("SET_START_TIME", Math.floor(Date.now() / 1000));
  },
  async handleLearnCard(context, payload) {
    let data = {
      userId: payload.userID,
      timeLearn: payload.timeLearn,
      typeLearn: payload.typeLearn,
      Qn: payload.Qn,
      step: payload.step,
      QnRelearn: payload.QnRelearn,
      QnEasy: payload.QnEasy,
      QnGood: payload.QnGood,
      QnHard: payload.QnHard,
      goodLevelFactor: payload.goodLevelFactor,
      numberOfRelearnPenalties: payload.numberOfRelearnPenalties,
      numberOfHardPenalties: payload.numberOfHardPenalties,
      word: payload.word,
      typeCard: payload.typeCard,
    };
    context.commit("PUSH_PREVIOUS_CARD", payload);
    await axios
      .put("user/cards/" + payload.id, data)
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async resetLearnCard(context, payload) {
    let data = {
      reminderTime: payload.reminderTime,
      timeLearn: payload.timeLearn,
      type: payload.type,
      step: payload.step,
      Qn: payload.Qn,
      QnRelearn: payload.QnRelearn,
      QnEasy: payload.QnEasy,
      QnGood: payload.QnGood,
      QnHard: payload.QnHard,
      goodLevelFactor: payload.goodLevelFactor,
      numberOfRelearnPenalties: payload.numberOfRelearnPenalties,
      numberOfHardPenalties: payload.numberOfHardPenalties,
      isBookmarked: payload.isBookmarked,
    };
    await axios
      .put("user/cards/update/" + payload.id, data)
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  setClosePopupSuccess(context, payload) {
    context.commit("SET_DONE_CURRENT_GROUP_CARD", payload);
  },
  setNextCardToCurrentCard(context, payload) {
    context.commit("SET_CURRENT_CARD", payload);
    context.commit("SET_NEXT_CARD", { id: 0 });
  },
  resetCard(context) {
    context.commit("SET_CURRENT_CARD", { id: 0 });
    context.commit("SET_NEXT_CARD", { id: 0 });
    context.commit("SET_PREVIOUS_TYPE_LEARN", -1);
    context.commit("SET_PREVIOUS_CARD_ID", null);
  },
  async handleSaveCard(context, payload) {
    try {
      const res = await axios.post("user/cards/save-card/save", payload);
      if (res.data.status_code != "200") {
        return "Đã có lỗi xảy ra, vui lòng thử lại";
      }
      context.commit("SET_DISABLE_SAVE", true);
      context.commit("SET_IS_OPEN_POP_UP_HOME_WORK", false);
      context.commit("SET_DONE_HOME_WORK", false);
    } catch (error) {
      return "Đã có lỗi xảy ra, vui lòng thử lại";
    }
    return "";
  },
  async handleUnSaveCard(context, payload) {
    try {
      const res = await axios.post("user/cards/save-card/unsave", payload);
      if (res.data.status_code != "200") {
        return "Đã có lỗi xảy ra, vui lòng thử lại";
      }
      context.commit("SET_DISABLE_UN_SAVE", true);
      context.commit("SET_IS_OPEN_POP_UP_HOME_WORK", false);
      context.commit("SET_DONE_HOME_WORK", false);
    } catch (error) {
      return "Đã có lỗi xảy ra, vui lòng thử lại";
    }
    return "";
  },
  async handleDeleteCardClone(context, payload) {
    try {
      const res = await axios.post(
        "user/cards/save-card/delete-a-copy",
        payload
      );
      if (res.data.status_code != "200") {
        return "Đã có lỗi xảy ra, vui lòng thử lại";
      }
      if (res.data.status_code == "200" && res.data.deleteGroup == "deleted") {
        context.commit("SET_NEXT_CARD", { id: 0 });
      }
    } catch (error) {
      console.log(error);
      return "Đã có lỗi xảy ra, vui lòng thử lại";
    }
    return "";
  },
  async getIsDoneHomeWork(context, payload) {
    try {
      const res = await axios.get(
        "admin/student/homework/" + payload.studentId
      );
      if (res.data.status_code !== "200") {
        return "Đã có lỗi xảy ra, vui lòng thử lại";
      }
      if (res.data.isCompleteHomeWork && !context.state.isOpenPopUpHomeWork) {
        context.commit("SET_DONE_HOME_WORK", true);
      }
    } catch (error) {
      console.log(error);
      return "Đã có lỗi xảy ra, vui lòng thử lại";
    }
    return "";
  },
  async getStudentCardHistory(context, studentId) {
    try {
      const res = await axios.get("user/cards/history/" + studentId);
      if (res.data.status_code !== "200") {
        return "Đã có lỗi xảy ra, vui lòng thử lại";
      }
      context.commit("SET_CARD_HISTORY", res.data.data);
    } catch (error) {
      console.log(error);
      return "Đã có lỗi xảy ra, vui lòng thử lại";
    }
    return "";
  },
  async getAdminCardHistory(context, adminId) {
    try {
      const res = await axios.get(BASE_URL + "user/cards/history/" + adminId);
      if (res.data.status_code !== "200") {
        return "Đã có lỗi xảy ra, vui lòng thử lại";
      }
      context.commit("SET_CARD_HISTORY", res.data.data);
    } catch (error) {
      console.log(error);
      return "Đã có lỗi xảy ra, vui lòng thử lại";
    }
    return "";
  },
};
