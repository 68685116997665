<template>
  <v-tabs v-model="tab">
    <template v-if="!$isSubAdmin">
      <v-tab href="#manage-card" @click="changeTab('manage-card')">
        <h6>Quản lý thẻ gốc</h6>
      </v-tab>
      <v-tab-item value="manage-card"><ManageOriginCardSet /></v-tab-item>

      <v-tab
        href="#manage-pro-card-set"
        @click="changeTab('manage-pro-card-set')"
      >
        <h6>Quản lý bộ thẻ Pro</h6>
      </v-tab>
      <v-tab-item value="manage-pro-card-set"
        ><ManageProCardSet
          :refreshCount="refreshCount"
          @refresh="refreshCount++"
      /></v-tab-item>
    </template>

    <v-tab href="#manage-student" @click="changeTab('manage-student')">
      <h6>Quản lý học sinh</h6>
    </v-tab>
    <v-tab-item value="manage-student"><ManageStudent /></v-tab-item>

    <v-tab href="#manage-study" @click="changeTab('manage-study')">
      <h6>Quản lý học tập</h6>
    </v-tab>
    <v-tab-item value="manage-study">
      <ManageLearning />
    </v-tab-item>
  </v-tabs>
</template>
<script>
import ManageOriginCardSet from "@/views/admin/ManageOriginCardSet.vue";
import ManageProCardSet from "@/views/admin/ManageProCardSet.vue";
import ManageStudent from "@/views/admin/ManageStudent.vue";
import ManageLearning from "@/views/admin/ManageLearning.vue";
export default {
  name: "TabsView",
  components: {
    ManageOriginCardSet,
    ManageProCardSet,
    ManageStudent,
    ManageLearning,
  },
  data() {
    return {
      tab: "",
      refreshCount: 0,
    };
  },
  created() {
    this.tab = this.$route.query.tab;
  },
  activated() {
    this.tab = this.$route.query.tab;
  },
  methods: {
    changeTab(tab) {
      this.tab = tab;
      this.$router.replace({ path: "/manage", query: { tab: this.tab } });
      if (this.tab === "manage-pro-card-set") this.refreshCount++;
    },
  },
};
</script>
<style lang="scss">
.v-tabs {
  height: calc(100% - 60px);
  .v-window {
    height: calc(100% - 48px);
  }
}
</style>
